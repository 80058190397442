<template>
  <nav class="justify-content-center">
    <ul class="nav nav-pills" role="menubar">
      <template v-for="(menu, i) in $store.getters.MENU_LIST_PARENT('000000')" :key="menu.MENU_ID">
        <li class="nav-item" role="none">
          <router-link
              v-if="menu.MENU_URL.startsWith('/')"
              :to="{ path: menu.MENU_URL }"
              class="nav-link p-0 position-relative"
              :class="{'kchf-user': isKCHFUser()}"
              role="menuitem"
              @click="topMenuClick(menu)"
              :ref="`router-${i}`"
              :aria-current="isLinkActive(menu.MENU_URL) ? 'page' : null"
          >
            {{ $t(menu.MENU_NAME) }}
            <span
                v-if="isNewNotice && !isCheckNotice && menu.MENU_ID === 'M00005'"
                class="position-absolute top-0 badge rounded-pill"
                style="
                font-size: 5px;
                background-color: #F15B2B;
                left: 106%;
              "
                :aria-label="$t('새 글 알림 아이콘')"
            >&nbsp;</span>
          </router-link>
          <button
              v-else
              type="button"
              class="nav-link p-0 position-relative"
              :class="{ 'kchf-user': isKCHFUser() }"
              role="menuitem"
              @click="goToCloud(menu.MENU_URL)"
          >
            {{ $t(menu.MENU_NAME) }}
          </button>
        </li>
      </template>
    </ul>
    <Preview v-if="previewShow" ref="preview" :selectedItem="selectedItem" />
  </nav>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { $site } from "dot-framework";
import global from "global";
import { router } from "@/routes";
import { $axios } from "@/js/dot-framework-vue-1.0.5";

const Preview = defineAsyncComponent(() => import("@/views/drive/Preview.vue"));

export default {
  data() {
    return {
      topMenuId: "",
      selectedItem: null,
      previewShow: false,
      noticeCheckDays: 14,
      isNewNotice: false,
    };
  },
  components: {
    Preview,
  },
  watch: {
    $route: {
      handler() {
        this.checkForNewNotice();
      },
      immediate: true,
    },
  },
  computed: {
    isCheckNotice() {
      const noticeData = this.getLocalStorageItem("isCheckNotice");
      const lastArticleNo = this.getLocalStorageItem("lastArticleNo")?.value;
      return noticeData && noticeData.expire > Date.now() && lastArticleNo === noticeData.articleNo
          ? noticeData.value
          : false;
    },
  },
  methods: {
    // 상단 메뉴 클릭
    topMenuClick(menu) {
      this.topMenuId = menu.MENU_ID;
      // (1) 상위메뉴ID store에 저장
      this.$store.commit("setTopMenuId", this.topMenuId);
      // (2) 첫번째 메뉴 처리
      const topMenuFirst = $site.getTopMenuFirst(menu, this.$store);
      this.$store.commit("setSelectedMenu", topMenuFirst.MENU_ID);

      if (this.isNewNotice && !this.isCheckNotice && this.topMenuId === "M00005") {
        this.updateNoticeCheckStatus();
      }
    },
    async goToCloud(groupNo) {
      if (!groupNo) {
        console.error("No groupNo provided");
        return;
      }
      router.push({ name: "cloud", query: { type: "P", parentGroupNo: groupNo } });
    },
    isLinkActive(path) {
      return this.$route.path === path;
    },
    isKCHFUser() {
      return global.isKCHFUser();
    },
    checkForNewNotice() {
      const noticeData = this.getLocalStorageItem("isNewNotice");
      if (noticeData && noticeData.expire > Date.now()) {
        this.isNewNotice = noticeData.value;
        return;
      }
      this.fetchLatestNotice();
    },
    fetchLatestNotice() {
      // TODO: 고객 지원 사이트 운영 서버 키값 변경 필요
      const SITE_NO = process.env.NODE_ENV === "production" ? "000000" : "S230627001";
      const COMP_NO = process.env.NODE_ENV === "production" ? "000000" : "C220121001";
      const BOARD_ID = process.env.NODE_ENV === "production" ? "000000" : "B00219";
      const uri = `/board-app/v1/sites/${SITE_NO}-${COMP_NO}/boards/${BOARD_ID}/articles`;

      const params = new URLSearchParams({
        PAGE_NO: "1",
        PAGE_SIZE: "1",
        LANGUAGE_CODE: "ko",
        PAGING_ORDER_BY: "DESC",
        BOARD_TYPE_KIND: "NOTICE",
      }).toString();

      $axios.get(`${uri}?${params}`).then((res) => {
        const item = res.data.items[0];
        const regDate = new Date(item.REG_DATE);
        const articleNo = item.ARTICLE_NO;
        const diffDays = (Date.now() - regDate) / (1000 * 60 * 60 * 24);

        if (diffDays < this.noticeCheckDays) {
          this.isNewNotice = true;
          this.setLocalStorageItem("isNewNotice", {
            value: this.isNewNotice,
            articleNo,
            expire: this.getNext10MinuteMark(),
          });
          this.setLocalStorageItem("lastArticleNo", {
            value: articleNo,
            expire: this.getEndOfDayTimestamp(),
          });
        } else {
          this.isNewNotice = false;
        }
      });
    },
    updateNoticeCheckStatus() {
      const articleNo = this.getLocalStorageItem("lastArticleNo")?.value;
      this.setLocalStorageItem("isCheckNotice", {
        value: true,
        articleNo,
        expire: this.getEndOfDayTimestamp(),
      });
    },
    getNext10MinuteMark() {
      const expirationDate = new Date();
      const minutes = expirationDate.getMinutes();
      const next10MinuteMark = Math.ceil((minutes + 1) / 10) * 10;
      expirationDate.setMinutes(next10MinuteMark, 0, 0);
      return expirationDate.getTime();
    },
    getEndOfDayTimestamp() {
      const endOfDay = new Date();
      endOfDay.setHours(23, 59, 59, 999);
      return endOfDay.getTime();
    },
    setLocalStorageItem(key, data) {
      window.localStorage.setItem(key, JSON.stringify(data));
    },
    getLocalStorageItem(key) {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    },
  },
};
</script>

<style scoped>
.nav {
  gap: 3.1rem;
}

.nav-link {
  text-decoration: none;
  color: #767474;
  font-size: 1.5rem;
}

.nav-link:hover {
  text-decoration: none;
  opacity: 0.8;
}

.router-link-active {
  color: #EA5414;
  background: unset;
}

.router-link-active.kchf-user {
  color: #183F3E;
}

@media all and (min-width: 1024px) and (max-width: 1279px) {
  .nav {
    gap: 2rem;
  }

  .nav-link {
    font-size: 1.3rem;
  }
}

@media all and (max-width: 1023px) {
  .nav {
    gap: 2rem;
  }

  .nav-link {
    font-size: 1.3rem;
  }
}
</style>
